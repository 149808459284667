import React, { useEffect } from 'react';
import { useRouteMatch, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import enviromentVariables from 'erpcore/utils/enviromentVariables';

import ProjectPageHeader from 'erpcore/screens/Projects/components/ProjectPageHeader';
import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';

const ProjectDeveloper = () => {
    const reducerName = 'projects';
    const actionName = 'PROJECTS';

    const match = useRouteMatch();
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const fetching = useSelector(getProjectFetching);
    const { client = {}, purchase_calculators: purchaseCalculators } = { ...projectData };

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    const fetchProjects = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params: {
                    pagination: false
                },
                entity: actionName,
                name: reducerName,
                endpoint: `/api/projects?include=client,city,state,country,featuredImage&filters[client][in][]=${projectData?.client?.iri}`
            });
        }).catch(error => {
            return error;
        });
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    useEffect(() => {
        if (projectData?.client?.iri) {
            fetchProjects();
        }
    }, [projectData?.client?.iri]);

    const routePrefix = enviromentVariables.REACT_APP_ROUTE_PREFIX;
    const projectName = match?.params?.project;

    const pageTitle = 'Developer';
    let pageHeaderLink = `${routePrefix}/${projectName}`;
    let pageHeaderLinkLabel = 'Home';
    if (purchaseCalculators?.length > 0) {
        pageHeaderLink = `${routePrefix}/${projectName}/purchase-calculator`;
        pageHeaderLinkLabel = 'Purchase Calculator';
    }

    const { logo_image: logoImage = '' } = client;
    const { content_url_modified: contentUrlModified } = logoImage;

    const activeProjects = listing.data.filter(project => {
        return project.is_active === true;
    });

    const previousProjects = listing.data.filter(project => {
        return project.is_active !== true;
    });

    const renderProjectCard = project => {
        const { featured_image: featuredImage = {} } = project;
        const { content_url_modified: image } = featuredImage;
        return (
            <NavLink
                to={`${routePrefix}/${project.id}`}
                className="projects__item"
                key={project.id}
            >
                <div
                    className="projects__item-image"
                    style={{ backgroundImage: `url(${image})` }}
                ></div>
                <div className="projects__item-content">
                    <span className="projects__item-location">
                        {project?.city?.name}
                        {project?.state?.name ? `/${project?.state?.name}` : ''}
                    </span>
                    <span className="projects__item-name">{project.name}</span>
                    <span className="projects__item-type">{project?.street}</span>
                </div>
                <div className="projects__item-action">Start Demo</div>
            </NavLink>
        );
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={pageTitle} />
            <ProjectPageHeader
                title={pageTitle}
                link={pageHeaderLink}
                linkLabel={pageHeaderLinkLabel}
            />
            <PageContent>
                {(fetching === true || listingFetching === true) && <PageLoader content />}
                <div className="projects">
                    <div className="projects__developer-logo">
                        <img src={contentUrlModified} alt={client?.name} />
                    </div>
                    <p
                        className="projects__subtitle"
                        dangerouslySetInnerHTML={{
                            __html: client?.description
                        }}
                    ></p>
                    {client.website && (
                        <p>
                            <a href={client.website} target="_blank" rel="noopener noreferrer">
                                Developer Website
                            </a>
                        </p>
                    )}
                    {previousProjects.length > 0 && (
                        <>
                            <p className="projects__subtitle">Active Projects</p>
                            <div className="projects__item-list">
                                {!listingFetching &&
                                    activeProjects.map(project => {
                                        return renderProjectCard(project);
                                    })}
                            </div>
                        </>
                    )}
                    {previousProjects.length > 0 && (
                        <>
                            <p className="projects__subtitle">Previous Projects</p>
                            <div className="projects__item-list">
                                {!listingFetching &&
                                    previousProjects.map(project => {
                                        return renderProjectCard(project);
                                    })}
                            </div>
                        </>
                    )}
                </div>
            </PageContent>
        </LayoutManager>
    );
};

ProjectDeveloper.defaultProps = {};

ProjectDeveloper.propTypes = {};

export default ProjectDeveloper;
