import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getOrganizationSettings } from 'erpcore/utils/OrganizationSettings/OrganizationSettings.selectors';
import LayoutManager from 'erpcore/utils/LayoutManager';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import ElementLoader from 'erpcore/components/ElementLoader';
import Image from 'erpcore/components/Image';
import frontLayout from 'erpcore/utils/LayoutManager/frontLayout';
import enviromentVariables from 'erpcore/utils/enviromentVariables';
import SignIn from 'erpcore/screens/Projects/components/SignIn';
import { getSignedIn } from 'erpcore/utils/AuthManager/AuthManager.selectors';

import './Projects.scss';

const Projects = () => {
    const reducerName = 'projects';
    const actionName = 'PROJECTS';

    const dispatch = useDispatch();
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const organizationSettings = useSelector(state => getOrganizationSettings(state)) || {};
    const isSignedIn = useSelector(getSignedIn);
    const title = 'Projects';

    const fetchProjects = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params: {
                    pagination: false,
                    'filters[is_active][equals]': true
                },
                entity: actionName,
                name: reducerName,
                endpoint: '/api/projects?include=client,state,country,city,featuredImage'
            });
        }).catch(error => {
            return error;
        });
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    const routePrefix = enviromentVariables.REACT_APP_ROUTE_PREFIX;

    return (
        <LayoutManager layout={frontLayout} slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <div className="projects">
                <div className="projects__logo-wrapper">
                    <Image
                        height="auto"
                        width="auto"
                        iri={organizationSettings?.settings?.logo}
                        className="projects__logo"
                    />
                </div>
                <p className="projects__subtitle">{organizationSettings?.settings?.introText}</p>
                {isSignedIn === false ? (
                    <div className="projects__sign-in">
                        <SignIn />
                    </div>
                ) : (
                    <div className="projects__item-list">
                        {!!listingFetching && <ElementLoader overlay />}
                        {!listingFetching &&
                            listing.data.map(project => {
                                const { featured_image: featuredImage = {} } = project;
                                const { content_url_modified: image } = featuredImage;
                                return (
                                    <NavLink
                                        to={`${routePrefix}/${project.id}`}
                                        className="projects__item"
                                        key={project.id}
                                    >
                                        <div
                                            className="projects__item-image"
                                            style={{ backgroundImage: `url(${image})` }}
                                        ></div>
                                        <div className="projects__item-content">
                                            <span className="projects__item-location">
                                                {project?.city?.name}
                                                {project?.state?.name
                                                    ? `/ ${project?.state?.name}`
                                                    : ''}
                                            </span>
                                            <span className="projects__item-name">
                                                {project.name}
                                            </span>
                                            <span className="projects__item-type">
                                                {project?.street}
                                            </span>
                                        </div>
                                        <div className="projects__item-action">Start Demo</div>
                                    </NavLink>
                                );
                            })}
                    </div>
                )}
            </div>
        </LayoutManager>
    );
};

export default Projects;
