import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useRouteMatch, NavLink } from 'react-router-dom';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import Svg from 'erpcore/components/Svg';

import { getProjectData } from 'erpcore/screens/Projects/Projects.selectors';
import './ProjectPageHeader.scss';

const ProjectPageHeader = ({ title, link, linkLabel }) => {
    const match = useRouteMatch();
    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};

    const renderLink = () => {
        let linkNode = null;

        linkNode = (
            <NavLink to={link} className="project-page-header__link">
                <span className="project-page-header__link-label">{linkLabel}</span>
                <Svg icon="arrow" />
            </NavLink>
        );

        return linkNode;
    };

    return (
        <PageHeader title={title} link={renderLink()}>
            <div className="project-page-header">
                <p className="project-page-header__name">{projectData.name}</p>
                <p className="project-page-header__address">{projectData.street}</p>
            </div>
        </PageHeader>
    );
};

ProjectPageHeader.defaultProps = {
    title: null,
    link: null,
    linkLabel: null
};

ProjectPageHeader.propTypes = {
    title: PropTypes.string,
    link: PropTypes.string,
    linkLabel: PropTypes.string
};

export default ProjectPageHeader;
