import React, { useEffect } from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import slugify from 'react-slugify';

import enviromentVariables from 'erpcore/utils/enviromentVariables';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import PdfViewer from 'erpcore/components/PdfViewer';
import SubNavigation from 'erpcore/components/Layout/SubNavigation';
import Gallery from 'erpcore/components/Gallery';
import Menu from 'erpcore/components/Layout/MainNavigation/components/Menu';
import FeaturesListSlider from 'erpcore/components/FeaturesListSlider';

import ProjectPageHeader from 'erpcore/screens/Projects/components/ProjectPageHeader';
import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import getImagesIrisFromObjects from 'erpcore/components/Gallery/Gallery.utils';

const ProjectBuildingDesign = () => {
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(getProjectFetching);

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const routePrefix = enviromentVariables.REACT_APP_ROUTE_PREFIX;
    const projectName = match?.params?.project;

    const pageTitle = 'Building Design';
    const pageHeaderLink = `${routePrefix}/${projectName}/floorplans`;
    const pageHeaderLinkLabel = 'Floor Plans';

    const { site_plan_images: sitePlanImages, architectural_plans: architecturalPlans } = {
        ...projectData
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={pageTitle} />
            <ProjectPageHeader
                title={pageTitle}
                link={pageHeaderLink}
                linkLabel={pageHeaderLinkLabel}
            />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <PageContent.Columns>
                    <PageContent.SideMenu>
                        <SubNavigation>
                            {!!projectData.exterior_rendering_images && (
                                <Menu.Item
                                    exact
                                    to={`${routePrefix}/${projectName}/building-design`}
                                    label="Exterior Rendering"
                                />
                            )}
                            {!!projectData.amenity_programming_gallery && (
                                <Menu.Item
                                    exact
                                    to={`${routePrefix}/${projectName}/building-design/amenity-programming-gallery`}
                                    label="Amenity Gallery"
                                />
                            )}
                            {!!projectData.amenity_programming_features && (
                                <Menu.Item
                                    exact
                                    to={`${routePrefix}/${projectName}/building-design/amenity-programming-features`}
                                    label="Amenity Features"
                                />
                            )}
                            {!!sitePlanImages && (
                                <Menu.Submenu label="Site Plan">
                                    {sitePlanImages?.map(item => (
                                        <Menu.SubmenuItem
                                            to={`${routePrefix}/${projectName}/building-design/site-plan/${slugify(
                                                item.title
                                            )}`}
                                            label={item.title}
                                            key={slugify(item.title)}
                                        />
                                    ))}
                                </Menu.Submenu>
                            )}
                            {!!architecturalPlans?.length && (
                                <Menu.Submenu label="Architectural Plan">
                                    {architecturalPlans?.map((item, i) => (
                                        <Menu.SubmenuItem
                                            to={`${routePrefix}/${projectName}/building-design/architectural-plan/${slugify(
                                                item.title || i + 1
                                            )}`}
                                            label={item.title || (i + 1).toString()}
                                            key={item.iri}
                                        />
                                    ))}
                                </Menu.Submenu>
                            )}
                        </SubNavigation>
                    </PageContent.SideMenu>

                    <Switch>
                        <Route exact path={`${routePrefix}/:project/building-design`}>
                            <PageContent.Column>
                                <Gallery
                                    name="exteriorRendering"
                                    imagesIris={getImagesIrisFromObjects(
                                        projectData.exterior_rendering_images
                                    )}
                                />
                            </PageContent.Column>
                        </Route>
                        {sitePlanImages?.map(item => (
                            <Route
                                exact
                                path={`${routePrefix}/:project/building-design/site-plan/${slugify(
                                    item.title
                                )}`}
                                key={slugify(item.title)}
                            >
                                <PageContent.Column>
                                    <Gallery
                                        name={`sitePlanGallery${item?.id}`}
                                        imagesIris={getImagesIrisFromObjects(item.gallery)}
                                    />
                                </PageContent.Column>
                            </Route>
                        ))}
                        <Route
                            exact
                            path={`${routePrefix}/:project/building-design/amenity-programming-features`}
                        >
                            <PageContent.Column>
                                <FeaturesListSlider
                                    featuresList={projectData.amenity_programming_features}
                                />
                            </PageContent.Column>
                        </Route>
                        <Route
                            exact
                            path={`${routePrefix}/:project/building-design/amenity-programming-gallery`}
                        >
                            <PageContent.Column>
                                <Gallery
                                    name="amenityProgrammingGallery"
                                    imagesIris={getImagesIrisFromObjects(
                                        projectData.amenity_programming_gallery
                                    )}
                                />
                            </PageContent.Column>
                        </Route>
                        {architecturalPlans?.map((item, i) => (
                            <Route
                                exact
                                path={`${routePrefix}/:project/building-design/architectural-plan/${slugify(
                                    item.title || i + 1
                                )}`}
                                key={item?.iri}
                            >
                                <PageContent.Column className="page-content__column--no-padding">
                                    <PdfViewer
                                        name={`architecturalPlans-${slugify(item.title || i + 1)}`}
                                        fileIri={item?.iri}
                                    />
                                </PageContent.Column>
                            </Route>
                        ))}
                    </Switch>
                </PageContent.Columns>
            </PageContent>
        </LayoutManager>
    );
};

ProjectBuildingDesign.defaultProps = {};

ProjectBuildingDesign.propTypes = {};

export default ProjectBuildingDesign;
