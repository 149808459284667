import React, { useEffect } from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import slugify from 'react-slugify';

import enviromentVariables from 'erpcore/utils/enviromentVariables';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import SubNavigation from 'erpcore/components/Layout/SubNavigation';
import Menu from 'erpcore/components/Layout/MainNavigation/components/Menu';
import Gallery from 'erpcore/components/Gallery';

import ProjectPageHeader from 'erpcore/screens/Projects/components/ProjectPageHeader';
import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import getImagesIrisFromObjects from 'erpcore/components/Gallery/Gallery.utils';

const ProjectFloorplans = () => {
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(getProjectFetching);

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const routePrefix = enviromentVariables.REACT_APP_ROUTE_PREFIX;
    const projectName = match?.params?.project;
    const pageTitle = 'Floor Plans';
    const pageHeaderLink = `${routePrefix}/${projectName}/home-tour`;
    const pageHeaderLinkLabel = 'Home Tour';

    const { floorplans, site_plan_images: sitePlanImages } = { ...projectData };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={pageTitle} />
            <ProjectPageHeader
                title={pageTitle}
                link={pageHeaderLink}
                linkLabel={pageHeaderLinkLabel}
            />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <PageContent.Columns>
                    <PageContent.SideMenu>
                        <SubNavigation>
                            {!!sitePlanImages && (
                                <Menu.Submenu
                                    label="Site plan"
                                    className="main-navigation__menu-item--open"
                                >
                                    {sitePlanImages?.map((item, index) => (
                                        <Menu.SubmenuItem
                                            to={`${routePrefix}/${projectName}/floorplans${
                                                index > 0 ? `/site-plan/${slugify(item.title)}` : ''
                                            }`}
                                            label={item.title}
                                            key={slugify(item.title)}
                                            exact
                                        />
                                    ))}
                                </Menu.Submenu>
                            )}
                            {floorplans?.map(item => (
                                <Menu.Item
                                    exact
                                    to={`${routePrefix}/${projectName}/floorplans/${slugify(
                                        item.unit_type
                                    )}`}
                                    label={item.unit_type}
                                    key={slugify(item.unit_type)}
                                />
                            ))}
                        </SubNavigation>
                    </PageContent.SideMenu>
                    <PageContent.Column>
                        <Switch>
                            {sitePlanImages?.map((item, index) => (
                                <Route
                                    exact
                                    path={`${routePrefix}/:project/floorplans${
                                        index > 0 ? `/site-plan/${slugify(item.title)}` : ''
                                    }`}
                                    key={slugify(item.title)}
                                >
                                    <Gallery
                                        name={`sitePlanGallery${item?.id}`}
                                        imagesIris={getImagesIrisFromObjects(item.gallery)}
                                    />
                                </Route>
                            ))}
                            {floorplans?.map((item, index) => (
                                <Route
                                    exact
                                    path={`${routePrefix}/:project/floorplans/${slugify(
                                        item.unit_type
                                    )}`}
                                    key={slugify(item.unit_type)}
                                >
                                    <Gallery
                                        name={`florplans-${index}`}
                                        imagesIris={getImagesIrisFromObjects(item.images)}
                                    />
                                </Route>
                            ))}
                        </Switch>
                    </PageContent.Column>
                </PageContent.Columns>
            </PageContent>
        </LayoutManager>
    );
};

ProjectFloorplans.defaultProps = {};

ProjectFloorplans.propTypes = {};

export default ProjectFloorplans;
