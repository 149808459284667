import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import Svg from 'erpcore/components/Svg';

import ProjectPageHeader from 'erpcore/screens/Projects/components/ProjectPageHeader';
import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import { getProjectFetching, getProjectData } from 'erpcore/screens/Projects/Projects.selectors';
import enviromentVariables from 'erpcore/utils/enviromentVariables';

import './ProjectPurchaseCalculator.scss';

const ProjectPurchaseCalculator = () => {
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(getProjectFetching);
    const { purchase_calculators: purchaseCalculators } = { ...projectData };

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const routePrefix = enviromentVariables.REACT_APP_ROUTE_PREFIX;
    const projectName = match?.params?.project;

    const pageTitle = 'Purchase Calculator';
    const pageHeaderLink = `${routePrefix}/${projectName}`;
    const pageHeaderLinkLabel = 'Home';

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={pageTitle} />
            <ProjectPageHeader
                title={pageTitle}
                link={pageHeaderLink}
                linkLabel={pageHeaderLinkLabel}
            />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <div className="purchase-calculator">
                    <div className="purchase-calculator__item-list">
                        {purchaseCalculators?.map(calculator => {
                            return (
                                <a
                                    href={calculator.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="purchase-calculator__item"
                                >
                                    <div className="purchase-calculator__item-content">
                                        <Svg
                                            icon="excel"
                                            className="purchase-calculator__item-icon"
                                        />
                                        <span className="purchase-calculator__item-name">
                                            {calculator.title}
                                        </span>
                                    </div>
                                </a>
                            );
                        })}
                    </div>
                </div>
            </PageContent>
        </LayoutManager>
    );
};

ProjectPurchaseCalculator.defaultProps = {};

ProjectPurchaseCalculator.propTypes = {};

export default ProjectPurchaseCalculator;
