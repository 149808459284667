import React, { useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getOrganizationSettings } from 'erpcore/utils/OrganizationSettings/OrganizationSettings.selectors';
import enviromentVariables from 'erpcore/utils/enviromentVariables';
import Menu from 'erpcore/components/Layout/MainNavigation/components/Menu';
import Image from 'erpcore/components/Image';

import { getProjectData } from 'erpcore/screens/Projects/Projects.selectors';

/**
 * Main Navigation
 * @param {Object} props
 * @return {Node} Returns Main Navigation
 */
const MainNavigation = () => {
    const match = useRouteMatch();
    const menuId = 'main-navigation';

    const organizationSettings = useSelector(state => getOrganizationSettings(state)) || {};

    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const { purchase_calculators: purchaseCalculators } = { ...projectData };

    /**
     * Effect used only on component mount
     */
    useEffect(() => {
        // Expand Submenu on Page Load
        const menu = document.getElementById(menuId);
        const activeItems = menu.querySelectorAll('.main-navigation__submenu-link--active');

        if (activeItems.length) {
            activeItems.forEach(activeItem => {
                activeItem
                    .closest('.main-navigation__menu-item')
                    .classList.add('main-navigation__menu-item--open');
            });
        }
    }, []);

    const routePrefix = enviromentVariables.REACT_APP_ROUTE_PREFIX;
    const projectName = match?.params?.project;

    return (
        <>
            <Link to={`${routePrefix}/`} className="main-navigation__logo">
                <Image iri={organizationSettings?.settings?.logo} />
            </Link>
            <Menu id={menuId}>
                <Menu.Item exact to={`${routePrefix}/${projectName}`} label="Home" />
                <Menu.Item
                    to={`${routePrefix}/${projectName}/neighbourhood`}
                    label="Neighbourhood"
                />
                <Menu.Item
                    to={`${routePrefix}/${projectName}/building-design`}
                    label="Building Design"
                />
                <Menu.Item to={`${routePrefix}/${projectName}/floorplans`} label="Floor Plans" />
                <Menu.Item to={`${routePrefix}/${projectName}/home-tour`} label="Home Tour" />
                <Menu.Item
                    to={`${routePrefix}/${projectName}/features-and-options`}
                    label="Features & Options"
                />
                <Menu.Item to={`${routePrefix}/${projectName}/developer`} label="Developer" />
                {purchaseCalculators?.length > 0 && (
                    <Menu.Item
                        to={`${routePrefix}/${projectName}/purchase-calculator`}
                        label="Purchase Calculator"
                    />
                )}
                <div className="main-navigation__menu-item--bottom">
                    <Menu.Item exact to={`${routePrefix}/`} label="Back To Projects" />
                    <Menu.Item exact to={`${routePrefix}/sign-out`} label="Sign Out" />
                </div>
            </Menu>
        </>
    );
};

export default MainNavigation;
