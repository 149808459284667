import eas from 'erpcore/assets/svg/eas.svg';
import approve from 'erpcore/assets/svg/approve.svg';
import cancel from 'erpcore/assets/svg/cancel.svg';
import chat from 'erpcore/assets/svg/chat.svg';
import easPowered from 'erpcore/assets/svg/easPowered.svg';
import dashboard from 'erpcore/assets/svg/dashboard.svg';
import date from 'erpcore/assets/svg/date.svg';
import dollar from 'erpcore/assets/svg/dollar.svg';
import drag from 'erpcore/assets/svg/drag.svg';
import globe from 'erpcore/assets/svg/globe.svg';
import reject from 'erpcore/assets/svg/reject.svg';
import remove from 'erpcore/assets/svg/remove.svg';
import edit from 'erpcore/assets/svg/edit.svg';
import editSquare from 'erpcore/assets/svg/editSquare.svg';
import excel from 'erpcore/assets/svg/excel.svg';
import favorites from 'erpcore/assets/svg/favorites.svg';
import favoritesFull from 'erpcore/assets/svg/favoritesFull.svg';
import filter from 'erpcore/assets/svg/filter.svg';
import group from 'erpcore/assets/svg/group.svg';
import help from 'erpcore/assets/svg/help.svg';
import link from 'erpcore/assets/svg/link.svg';
import linkExternal from 'erpcore/assets/svg/linkExternal.svg';
import location from 'erpcore/assets/svg/location.svg';
import lock from 'erpcore/assets/svg/lock.svg';
import locked from 'erpcore/assets/svg/locked.svg';
import logo from 'erpcore/assets/svg/logo.svg';
import mail from 'erpcore/assets/svg/mail.svg';
import menu from 'erpcore/assets/svg/menu.svg';
import minus from 'erpcore/assets/svg/minus.svg';
import minusCircle from 'erpcore/assets/svg/minusCircle.svg';
import move from 'erpcore/assets/svg/move.svg';
import notificationError from 'erpcore/assets/svg/notificationError.svg';
import notificationSuccess from 'erpcore/assets/svg/notificationSuccess.svg';
import notificationWarning from 'erpcore/assets/svg/notificationWarning.svg';
import passHide from 'erpcore/assets/svg/passHide.svg';
import passShow from 'erpcore/assets/svg/passShow.svg';
import percent from 'erpcore/assets/svg/percent.svg';
import plus from 'erpcore/assets/svg/plus.svg';
import profile from 'erpcore/assets/svg/profile.svg';
import signOut from 'erpcore/assets/svg/signOut.svg';
import search from 'erpcore/assets/svg/search.svg';
import settings from 'erpcore/assets/svg/settings.svg';
import sorting from 'erpcore/assets/svg/sorting.svg';
import statistics from 'erpcore/assets/svg/statistics.svg';
import time from 'erpcore/assets/svg/time.svg';
import upload from 'erpcore/assets/svg/upload.svg';
import video from 'erpcore/assets/svg/video.svg';
import view360 from 'erpcore/assets/svg/view360.svg';
import elementLoader from 'erpcore/assets/svg/elementLoader.svg';
import close from 'erpcore/assets/svg/close.svg';
import arrowDown from 'erpcore/assets/svg/arrowDown.svg';
import arrowLeft from 'erpcore/assets/svg/arrowLeft.svg';
import arrowRight from 'erpcore/assets/svg/arrowRight.svg';
import checkmark from 'erpcore/assets/svg/checkmark.svg';
import action from 'erpcore/assets/svg/action.svg';
import notFound from 'erpcore/assets/svg/notFound.svg';
import file from 'erpcore/assets/svg/file.svg';
import arrowDownRight from 'erpcore/assets/svg/arrowDownRight.svg';

//
import arrow from 'erpcore/assets/svg/arrow.svg';
import building from 'erpcore/assets/svg/building.svg';
import buildingBig from 'erpcore/assets/svg/buildingBig.svg';
import calculator from 'erpcore/assets/svg/calculator.svg';
import developer from 'erpcore/assets/svg/developer.svg';
import developerBig from 'erpcore/assets/svg/developerBig.svg';
import features from 'erpcore/assets/svg/features.svg';
import featuresBig from 'erpcore/assets/svg/featuresBig.svg';
import floorplan from 'erpcore/assets/svg/floorplan.svg';
import floorplanBig from 'erpcore/assets/svg/floorplanBig.svg';
import fullscreenOff from 'erpcore/assets/svg/fullscreenOff.svg';
import fullscreenOn from 'erpcore/assets/svg/fullscreenOn.svg';
import gallery from 'erpcore/assets/svg/gallery.svg';
import home from 'erpcore/assets/svg/home.svg';
import hood from 'erpcore/assets/svg/hood.svg';
import hoodBig from 'erpcore/assets/svg/hoodBig.svg';
import tour from 'erpcore/assets/svg/tour.svg';
import tourBig from 'erpcore/assets/svg/tourBig.svg';
import zoom from 'erpcore/assets/svg/zoom.svg';

export default {
    arrow,
    building,
    buildingBig,
    calculator,
    developer,
    developerBig,
    excel,
    features,
    featuresBig,
    floorplan,
    floorplanBig,
    gallery,
    home,
    hood,
    hoodBig,
    tour,
    tourBig,
    approve,
    eas,
    easPowered,
    cancel,
    chat,
    date,
    dollar,
    drag,
    dashboard,
    globe,
    reject,
    remove,
    edit,
    editSquare,
    favorites,
    favoritesFull,
    filter,
    fullscreenOff,
    fullscreenOn,
    group,
    help,
    link,
    linkExternal,
    location,
    lock,
    locked,
    logo,
    mail,
    menu,
    minus,
    minusCircle,
    move,
    notificationError,
    notificationSuccess,
    notificationWarning,
    percent,
    profile,
    passHide,
    passShow,
    plus,
    signOut,
    search,
    settings,
    sorting,
    statistics,
    time,
    upload,
    video,
    view360,
    elementLoader,
    close,
    arrowDown,
    arrowLeft,
    arrowRight,
    checkmark,
    action,
    notFound,
    file,
    arrowDownRight,
    zoom
};
