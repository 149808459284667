import React, { useEffect } from 'react';
import { useRouteMatch, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import enviromentVariables from 'erpcore/utils/enviromentVariables';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import Svg from 'erpcore/components/Svg';

import ProjectPageHeader from 'erpcore/screens/Projects/components/ProjectPageHeader';
import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import { getProjectFetching, getProjectData } from 'erpcore/screens/Projects/Projects.selectors';

import './ProjectDemoTourOverview.scss';

const ProjectDemoTourOverview = () => {
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(getProjectFetching);

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const routePrefix = enviromentVariables.REACT_APP_ROUTE_PREFIX;
    const projectName = match?.params?.project;

    const pageTitle = 'Demo Overview';
    const pageHeaderLink = `${routePrefix}/${projectName}/neighbourhood`;
    const pageHeaderLinkLabel = 'Neighbourhood';

    const {
        neighbourhood_google_maps: neighbourhoodGoogleMaps,
        neighbourhood_google_street: neighbourhoodGoogleStreet,
        site_plan_images: sitePlanImages = [],
        home_tour_gallery: homeTourGallery = [],
        home_tour_panoramas: homeTourPanoramas = [],
        features_list: featuresList = [],
        floorplans = []
    } = { ...projectData };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={pageTitle} />
            <ProjectPageHeader
                title={pageTitle}
                link={pageHeaderLink}
                linkLabel={pageHeaderLinkLabel}
            />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <div className="demo-tour-overview">
                    <div className="demo-tour-overview__item-list">
                        <NavLink
                            to={`${routePrefix}/${projectName}/neighbourhood`}
                            className="demo-tour-overview__item"
                        >
                            <div className="demo-tour-overview__item-content">
                                <Svg icon="hoodBig" className="demo-tour-overview__item-icon" />
                                <span className="demo-tour-overview__item-name">Neighbourhood</span>
                            </div>
                            <div className="demo-tour-overview__item-description">
                                <ul className="demo-tour-overview__item-description">
                                    {!!projectData.neighbourhood_gallery && (
                                        <li className="demo-tour-overview__item-description-line">
                                            Neighbourhood Gallery
                                        </li>
                                    )}
                                    {!!neighbourhoodGoogleMaps && (
                                        <li className="demo-tour-overview__item-description-line">
                                            Google Maps
                                        </li>
                                    )}
                                    {!!neighbourhoodGoogleStreet && (
                                        <li className="demo-tour-overview__item-description-line">
                                            Google Street View
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </NavLink>
                        <NavLink
                            to={`${routePrefix}/${projectName}/building-design`}
                            className="demo-tour-overview__item"
                        >
                            <div className="demo-tour-overview__item-content">
                                <Svg icon="buildingBig" className="demo-tour-overview__item-icon" />
                                <span className="demo-tour-overview__item-name">
                                    Building Design
                                </span>
                            </div>
                            <div className="demo-tour-overview__item-description">
                                <ul className="demo-tour-overview__item-description">
                                    {!!projectData.exterior_rendering_images && (
                                        <li className="demo-tour-overview__item-description-line">
                                            Exterior Rendering
                                        </li>
                                    )}
                                    {!!projectData.amenity_programming_gallery && (
                                        <li className="demo-tour-overview__item-description-line">
                                            Amenity Gallery
                                        </li>
                                    )}
                                    {!!projectData.amenity_programming_features && (
                                        <li className="demo-tour-overview__item-description-line">
                                            Amenity Features
                                        </li>
                                    )}
                                    {sitePlanImages > 1 && (
                                        <li className="demo-tour-overview__item-description-line">
                                            Site Plan
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </NavLink>
                        <NavLink
                            to={`${routePrefix}/${projectName}/floorplans`}
                            className="demo-tour-overview__item"
                        >
                            <div className="demo-tour-overview__item-content">
                                <Svg
                                    icon="floorplanBig"
                                    className="demo-tour-overview__item-icon"
                                />
                                <span className="demo-tour-overview__item-name">Floor Plans</span>
                            </div>
                            <div className="demo-tour-overview__item-description">
                                <ul className="demo-tour-overview__item-description">
                                    {sitePlanImages?.length > 1 && (
                                        <li className="demo-tour-overview__item-description-line">
                                            Site Plan
                                        </li>
                                    )}
                                    {floorplans?.length > 1 && (
                                        <li className="demo-tour-overview__item-description-line">
                                            Floor plan images by Unit type
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </NavLink>
                        <NavLink
                            to={`${routePrefix}/${projectName}/home-tour`}
                            className="demo-tour-overview__item"
                        >
                            <div className="demo-tour-overview__item-content">
                                <Svg icon="tourBig" className="demo-tour-overview__item-icon" />
                                <span className="demo-tour-overview__item-name">Home Tour</span>
                            </div>
                            <div className="demo-tour-overview__item-description">
                                <ul className="demo-tour-overview__item-description">
                                    {!!projectData.home_tour_sales_centre3d_tour && (
                                        <li className="demo-tour-overview__item-description-line">
                                            Sales Centre 3D Tour
                                        </li>
                                    )}
                                    {homeTourGallery?.length > 1 && (
                                        <li className="demo-tour-overview__item-description-line">
                                            Sales Centre Video Tour
                                        </li>
                                    )}
                                    {homeTourPanoramas?.length > 1 && (
                                        <li className="demo-tour-overview__item-description-line">
                                            Show Home Video Tour
                                        </li>
                                    )}
                                    {!!projectData.home_tour_show_home_video_tour && (
                                        <li className="demo-tour-overview__item-description-line">
                                            360 view
                                        </li>
                                    )}
                                    {!!projectData.home_tour_sales_centre_video_tour && (
                                        <li className="demo-tour-overview__item-description-line">
                                            Home Tour Gallery
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </NavLink>
                        <NavLink
                            to={`${routePrefix}/${projectName}/features-and-options`}
                            className="demo-tour-overview__item"
                        >
                            <div className="demo-tour-overview__item-content">
                                <Svg icon="featuresBig" className="demo-tour-overview__item-icon" />
                                <span className="demo-tour-overview__item-name">
                                    Features & Options
                                </span>
                            </div>
                            <div className="demo-tour-overview__item-description">
                                <ul className="demo-tour-overview__item-description">
                                    {!!projectData.features_gallery && (
                                        <li className="demo-tour-overview__item-description-line">
                                            Features Gallery
                                        </li>
                                    )}
                                    {!!projectData.home_tour_color_board && (
                                        <li className="demo-tour-overview__item-description-line">
                                            Color Board
                                        </li>
                                    )}
                                    {featuresList?.length > 1 && (
                                        <li className="demo-tour-overview__item-description-line">
                                            Features list
                                        </li>
                                    )}
                                    {!!projectData.options_gallery && (
                                        <li className="demo-tour-overview__item-description-line">
                                            Options gallery
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </NavLink>
                        <NavLink
                            to={`${routePrefix}/${projectName}/developer`}
                            className="demo-tour-overview__item"
                        >
                            <div className="demo-tour-overview__item-content">
                                <Svg
                                    icon="developerBig"
                                    className="demo-tour-overview__item-icon"
                                />
                                <span className="demo-tour-overview__item-name">Developer</span>
                            </div>
                            <div className="demo-tour-overview__item-description">
                                <ul className="demo-tour-overview__item-description">
                                    <li className="demo-tour-overview__item-description-line">
                                        About
                                    </li>
                                </ul>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </PageContent>
        </LayoutManager>
    );
};

ProjectDemoTourOverview.defaultProps = {};

ProjectDemoTourOverview.propTypes = {};

export default ProjectDemoTourOverview;
