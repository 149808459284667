import React, { useEffect } from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import enviromentVariables from 'erpcore/utils/enviromentVariables';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import SubNavigation from 'erpcore/components/Layout/SubNavigation';
import Menu from 'erpcore/components/Layout/MainNavigation/components/Menu';
import Gallery from 'erpcore/components/Gallery';
import FeaturesListSlider from 'erpcore/components/FeaturesListSlider';

import ProjectPageHeader from 'erpcore/screens/Projects/components/ProjectPageHeader';
import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import getImagesIrisFromObjects from 'erpcore/components/Gallery/Gallery.utils';

const ProjectFeatureAndOptions = () => {
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(getProjectFetching);

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const routePrefix = enviromentVariables.REACT_APP_ROUTE_PREFIX;
    const projectName = match?.params?.project;
    const pageTitle = 'Features & Options';
    const pageHeaderLink = `${routePrefix}/${projectName}/developer`;
    const pageHeaderLinkLabel = 'Developer';

    const { features_list: featuresList } = {
        ...projectData
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={pageTitle} />
            <ProjectPageHeader
                title={pageTitle}
                link={pageHeaderLink}
                linkLabel={pageHeaderLinkLabel}
            />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <PageContent.Columns>
                    <PageContent.SideMenu>
                        <SubNavigation>
                            {!!projectData.features_gallery && (
                                <Menu.Item
                                    exact
                                    to={`${routePrefix}/${projectName}/features-and-options`}
                                    label="Features Gallery"
                                />
                            )}
                            {!!projectData.home_tour_color_board && (
                                <Menu.Item
                                    exact
                                    to={`${routePrefix}/${projectName}/features-and-options/color-board`}
                                    label="Color Board"
                                />
                            )}
                            {!!featuresList && (
                                <Menu.Item
                                    exact
                                    to={`${routePrefix}/${projectName}/features-and-options/features-list`}
                                    label="Features List"
                                />
                            )}
                            {!!projectData.options_gallery && (
                                <Menu.Item
                                    exact
                                    to={`${routePrefix}/${projectName}/features-and-options/options-gallery`}
                                    label="Options Gallery"
                                />
                            )}
                        </SubNavigation>
                    </PageContent.SideMenu>
                    <PageContent.Column>
                        <Switch>
                            {!!projectData.features_gallery && (
                                <Route exact path={`${routePrefix}/:project/features-and-options`}>
                                    <Gallery
                                        name="featuresGallery"
                                        imagesIris={getImagesIrisFromObjects(
                                            projectData.features_gallery
                                        )}
                                    />
                                </Route>
                            )}
                            {!!projectData.home_tour_color_board && (
                                <Route
                                    exact
                                    path={`${routePrefix}/:project/features-and-options/color-board`}
                                >
                                    <Gallery
                                        name="colorBoard"
                                        imagesIris={getImagesIrisFromObjects(
                                            projectData.home_tour_color_board
                                        )}
                                    />
                                </Route>
                            )}
                            {!!featuresList && (
                                <Route
                                    exact
                                    path={`${routePrefix}/:project/features-and-options/features-list`}
                                >
                                    <FeaturesListSlider featuresList={featuresList} />
                                </Route>
                            )}
                            {!!projectData.options_gallery && (
                                <Route
                                    exact
                                    path={`${routePrefix}/:project/features-and-options/options-gallery`}
                                >
                                    <Gallery
                                        name="optionsGallery"
                                        imagesIris={getImagesIrisFromObjects(
                                            projectData.options_gallery
                                        )}
                                    />
                                </Route>
                            )}
                        </Switch>
                    </PageContent.Column>
                </PageContent.Columns>
            </PageContent>
        </LayoutManager>
    );
};

ProjectFeatureAndOptions.defaultProps = {};

ProjectFeatureAndOptions.propTypes = {};

export default ProjectFeatureAndOptions;
