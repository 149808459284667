import axios from 'axios';
import { store } from 'erpcore/store';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';

/**
 * Preparing rest client for react app
 * Here should be all default params defined
 */
const restClient = axios.create({
    baseURL: process.env.REACT_APP_REST_API,
    timeout: process.env.REACT_APP_API_TIMEOUT
    // headers: { Accept: ' application/vnd.api+json' }
});

const externalRestClient = axios.create({
    timeout: process.env.REACT_APP_API_TIMEOUT
});

// add the Auth header to the base API instance once here to avoid repeated code
if (localStorage.getItem('token')) {
    const token = localStorage.getItem('token');
    restClient.defaults.headers.Authorization = `Bearer ${token}`;
}

// handle generic events - like loading and 500 type errors - in API interceptors
restClient.interceptors.request.use(config => {
    // display a single subtle loader on the top of the page when there is networking in progress
    // avoid multiple loaders, use placeholders or consistent updates instead
    return config;
});

restClient.interceptors.response.use(
    resp => {
        return resp;
    },
    err => {
        // if you have no specific plan B for errors, let them be handled here with a notification
        const { status, data } = { ...err.response };

        // Sign Out if expired JWT
        if (data?.code === 'expiredJWT' || data?.code === 'invalidJWT') {
            store.dispatch({ type: authActions.START_SIGN_OUT });
        }

        if (status === 404) {
            if (err.response.data && !err.response.data.code) {
                err.response.data = {
                    code: 'error404'
                };
            }
        } else if (status === 405) {
            err.response.data = {
                code: 'error405'
            };
        } else if (status >= 500) {
            err.response.data = {
                code: 'error500'
            };
            // const message = data.message || 'Ooops, something bad happened.';
            // console.error(message);
        }

        throw err;
    }
);

export { externalRestClient };

export default restClient;
