import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import enviromentVariables from 'erpcore/utils/enviromentVariables';

import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as routerManagerActions } from 'erpcore/utils/RouterManager/RouterManager.reducer';
import { getSignedIn } from 'erpcore/utils/AuthManager/AuthManager.selectors';

import SignOut from 'erpcore/screens/SignOut';
import Projects from 'erpcore/screens/Projects/screens/Projects';
import ProjectHome from 'erpcore/screens/Projects/screens/ProjectHome';
import ProjectDemoTourOverview from 'erpcore/screens/Projects/screens/ProjectDemoTourOverview';
import ProjectBuildingDesign from 'erpcore/screens/Projects/screens/ProjectBuildingDesign';
import ProjectFeatureAndOptions from 'erpcore/screens/Projects/screens/ProjectFeatureAndOptions';
import ProjectFloorplans from 'erpcore/screens/Projects/screens/ProjectFloorplans';
import ProjectNeighbourhood from 'erpcore/screens/Projects/screens/ProjectNeighbourhood';
import ProjectHomeTour from 'erpcore/screens/Projects/screens/ProjectHomeTour';
import ProjectDeveloper from 'erpcore/screens/Projects/screens/ProjectDeveloper';
import ProjectPurchaseCalculator from 'erpcore/screens/Projects/screens/ProjectPurchaseCalculator';

/**
 * Router Manager
 * @return {Node} Returns current active route component
 */
const RouterManager = ({ location }) => {
    const dispatch = useDispatch();
    const allowedToClearNotifications = useRef(false);
    const { pathname, search } = location;
    const isSignedIn = useSelector(getSignedIn);

    const clearNotificationManagerPageNotification = () => {
        dispatch({
            type: notificationManagerActions.REMOVE_PAGE_NOTIFICATIONS,
            initiator: 'router'
        });
    };

    const setUrlQueryParams = params => {
        dispatch({
            type: routerManagerActions.SET_URL_QUERY_PARAMS,
            response: params
        });
    };

    /**
     * Effect used only on location path (route) change
     */
    useEffect(() => {
        const handleLocationChange = () => {
            if (allowedToClearNotifications.current) {
                // Clear Page Notifications
                clearNotificationManagerPageNotification();
            }
            allowedToClearNotifications.current = true;
            setUrlQueryParams('');
        };

        const renderBodyClassName = () => {
            //  dynamicly udating body className
            let cssClass = 'page-homepage';
            if (pathname && pathname !== '/') {
                cssClass = location.pathname.replace(/\//, 'page-');
                cssClass = cssClass.replace(/\//g, '-');
            } else {
                cssClass = 'page-404';
            }

            document.body.className = cssClass;
        };
        // Invoke functions
        renderBodyClassName();
        handleLocationChange();
    }, [pathname]);

    /**
     * Effect used only on location search (query params) change
     */
    useEffect(() => {
        setUrlQueryParams(search);
    }, [search]);

    // Render the public router when the user is not signed in
    const routePrefix = enviromentVariables.REACT_APP_ROUTE_PREFIX;
    return (
        <Switch>
            <Route exact path={`${routePrefix}/`} component={Projects} />
            {isSignedIn && (
                <>
                    <Route path={`${routePrefix}/sign-out`} exact component={SignOut} />
                    <Route exact path={`${routePrefix}/:project`} component={ProjectHome} />
                    <Route
                        exact
                        path={`${routePrefix}/:project/demo-tour-overview`}
                        component={ProjectDemoTourOverview}
                    />
                    <Route
                        path={`${routePrefix}/:project/building-design`}
                        component={ProjectBuildingDesign}
                    />
                    <Route
                        path={`${routePrefix}/:project/neighbourhood`}
                        component={ProjectNeighbourhood}
                    />
                    <Route
                        path={`${routePrefix}/:project/floorplans`}
                        component={ProjectFloorplans}
                    />
                    <Route path={`${routePrefix}/:project/home-tour`} component={ProjectHomeTour} />
                    <Route
                        path={`${routePrefix}/:project/features-and-options`}
                        component={ProjectFeatureAndOptions}
                    />
                    <Route
                        exact
                        path={`${routePrefix}/:project/developer`}
                        component={ProjectDeveloper}
                    />
                    <Route
                        path={`${routePrefix}/:project/purchase-calculator`}
                        component={ProjectPurchaseCalculator}
                    />
                </>
            )}
            <Redirect exact to={`${routePrefix}/`} />
        </Switch>
    );
};

RouterManager.defaultProps = {
    location: {}
};

RouterManager.propTypes = {
    location: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(RouterManager);
