import { all } from 'redux-saga/effects';
import { authManagerSaga } from 'erpcore/utils/AuthManager/AuthManager.saga';
import activityLogSaga from 'erpcore/components/ActivityLog/ActivityLog.saga';
import { listingSaga } from 'erpcore/components/Listing/Listing.saga';
import { commentsSaga } from 'erpcore/components/Comments/Comments.saga';
import gallerySaga from 'erpcore/components/Gallery/Gallery.saga';
import projectsSaga from 'erpcore/screens/Projects/Projects.saga';
import organizationSettingsSaga from 'erpcore/utils/OrganizationSettings/OrganizationSettings.saga';
import imageManagerSaga from 'erpcore/components/ImageManager/ImageManager.saga';

export default function* rootSaga() {
    yield all([...authManagerSaga]);
    yield all([...activityLogSaga]);
    yield all([...listingSaga]);
    yield all([...commentsSaga]);
    yield all([...gallerySaga]);
    yield all([...projectsSaga]);
    yield all([...organizationSettingsSaga]);
    yield all([...imageManagerSaga]);
}
