import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch, matchPath, NavLink } from 'react-router-dom';
import enviromentVariables from 'erpcore/utils/enviromentVariables';

import Svg from 'erpcore/components/Svg';

import LayoutManager from 'erpcore/utils/LayoutManager';
import { getSidebarShowDeck } from 'erpcore/utils/LayoutManager/LayoutManager.selectors';
import { actions as layoutManagerActions } from 'erpcore/utils/LayoutManager/LayoutManager.reducer';
import { getProjectData } from 'erpcore/screens/Projects/Projects.selectors';
import './Sidebar.scss';

const Sidebar = ({ className, layout }) => {
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const showDeck = useSelector(getSidebarShowDeck);
    const [showProfileMenu, setShowProfileMenu] = useState(false);

    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const { purchase_calculators: purchaseCalculators } = { ...projectData };

    /**
     * Used in multiple functions to update Redux state value
     * @param {boolean} value
     */
    const updateSidebarDeckState = value => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: layoutManagerActions.UPDATE_SIDEBAR_DECK_STATE,
                response: value
            })
        ).catch(error => {
            return console.error(error);
        });
    };

    /**
     * Effect used only on component mount
     */
    useEffect(() => {
        // Set Deck to hidden/colsed on mobile
        const isMobile = window && window.innerWidth < 1030;
        if (isMobile) {
            updateSidebarDeckState(false);
        }
    }, []);

    /**
     * Used for the click on the overlay
     */
    const hideAll = () => {
        setShowProfileMenu(false);
        return updateSidebarDeckState(false);
    };

    /**
     * Used by the hamburger button
     */
    const handleDeck = () => {
        setShowProfileMenu(false);
        return updateSidebarDeckState(!showDeck);
    };

    const routePrefix = enviromentVariables.REACT_APP_ROUTE_PREFIX;
    const projectName = match?.params?.project;

    const getMenuItemClass = (pathname, additionalCssClass) => {
        let menuItemClassName = `sidebar__bolt-top-menu-icon ${additionalCssClass || ''}`;

        const matched = matchPath(pathname, match);
        if (
            // standard check
            matched?.isExact ||
            // or if pathname param equals project home and demo-tour-overview is current page
            (pathname === `${routePrefix}/${projectName}` &&
                match.url === `${routePrefix}/${projectName}/demo-tour-overview`)
        ) {
            menuItemClassName = `sidebar__bolt-top-menu-icon ${additionalCssClass || ''} active`;
        }

        return menuItemClassName;
    };

    return (
        <aside
            className={`sidebar ${showDeck ? 'sidebar--show-deck' : ''} ${
                showProfileMenu ? 'sidebar--profile-opened' : ''
            } ${className || ''}`}
        >
            <button
                type="button"
                className="sidebar__mobile-overlay"
                onClick={() => hideAll()}
                onKeyPress={() => hideAll()}
            />
            <div className="sidebar__content">
                <div className="sidebar__bolt">
                    <ul className="sidebar__bolt-top">
                        {purchaseCalculators?.length > 0 && (
                            <li
                                className={getMenuItemClass(
                                    `${routePrefix}/${projectName}/purchase-calculator`
                                )}
                            >
                                <NavLink
                                    to={`${routePrefix}/${projectName}/purchase-calculator`}
                                    className="sidebar__button"
                                >
                                    <Svg icon="calculator" />
                                </NavLink>
                            </li>
                        )}
                        <li className={getMenuItemClass(`${routePrefix}/${projectName}/developer`)}>
                            <NavLink
                                to={`${routePrefix}/${projectName}/developer`}
                                className="sidebar__button"
                            >
                                <Svg icon="developer" />
                            </NavLink>
                        </li>
                        <li
                            className={getMenuItemClass(
                                `${routePrefix}/${projectName}/features-and-options`
                            )}
                        >
                            <NavLink
                                to={`${routePrefix}/${projectName}/features-and-options`}
                                className="sidebar__button"
                            >
                                <Svg icon="features" />
                            </NavLink>
                        </li>
                        <li className={getMenuItemClass(`${routePrefix}/${projectName}/home-tour`)}>
                            <NavLink
                                to={`${routePrefix}/${projectName}/home-tour`}
                                className="sidebar__button"
                            >
                                <Svg icon="tour" />
                            </NavLink>
                        </li>
                        <li
                            className={getMenuItemClass(`${routePrefix}/${projectName}/floorplans`)}
                        >
                            <NavLink
                                to={`${routePrefix}/${projectName}/floorplans`}
                                className="sidebar__button"
                            >
                                <Svg icon="floorplan" />
                            </NavLink>
                        </li>
                        <li
                            className={getMenuItemClass(
                                `${routePrefix}/${projectName}/building-design`
                            )}
                        >
                            <NavLink
                                to={`${routePrefix}/${projectName}/building-design`}
                                className="sidebar__button"
                            >
                                <Svg icon="building" />
                            </NavLink>
                        </li>
                        <li
                            className={getMenuItemClass(
                                `${routePrefix}/${projectName}/neighbourhood`
                            )}
                        >
                            <NavLink
                                to={`${routePrefix}/${projectName}/neighbourhood`}
                                className="sidebar__button"
                            >
                                <Svg icon="hood" />
                            </NavLink>
                        </li>
                        <li
                            className={getMenuItemClass(
                                `${routePrefix}/${projectName}`,
                                'sidebar__bolt-top-menu-icon--first'
                            )}
                        >
                            <NavLink
                                to={`${routePrefix}/${projectName}`}
                                className="sidebar__button"
                                exact
                            >
                                <Svg icon="home" />
                            </NavLink>
                        </li>
                        <li className="sidebar__bolt-top-hamburger">
                            <button
                                type="button"
                                className="sidebar__button sidebar__button--hamburger"
                                onClick={() => handleDeck()}
                                onKeyPress={() => handleDeck()}
                            >
                                <Svg icon="menu" />
                            </button>
                        </li>
                    </ul>
                    <ul className="sidebar__bolt-bottom">
                        <li className="sidebar__bolt-bottom-item">
                            <NavLink to={`${routePrefix}/`} className="sidebar__button" exact>
                                <Svg icon="arrowLeft" />
                            </NavLink>
                        </li>
                        <li className="sidebar__bolt-bottom-item">
                            <NavLink
                                to={`${routePrefix}/sign-out`}
                                className="sidebar__button"
                                exact
                            >
                                <Svg icon="signOut" />
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className="sidebar__deck">
                    <LayoutManager layout={layout} />
                </div>
            </div>
        </aside>
    );
};

Sidebar.defaultProps = {
    layout: null,
    className: ''
};

Sidebar.propTypes = {
    layout: PropTypes.oneOfType([PropTypes.object]),
    className: PropTypes.string
};

export default Sidebar;
